@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --hsl-background: hsl(224 71.4% 4.1%);
    --background: 0 0% 98%;
    --hsl-foreground: hsl(210 20% 98%);
    --foreground: 20 10% 5%;
    --card: 0 0% 100%;
    --hsl-card-foreground: hsl(210 20% 98%);
    --card-foreground: 20 10% 5%;
    --hsl-popover: hsl(224 71.4% 4.1%);
    --popover: 0 0% 100%;
    --hsl-popover-foreground: hsl(210 20% 98%);
    --popover-foreground: 20 10% 5%;
    --hsl-primary: hsl(263.4 70% 50.4%);
    --primary: 230 95% 50%;
    --hsl-primary-alt: hsl(340 75% 55%);
    --primary-alt: 25 100% 60%;
    --hsl-primary-foreground: hsl(210 20% 98%);
    --primary-foreground: 0 0% 100%;
    --secondary: 25 30% 95%;
    --hsl-secondary-foreground: hsl(210 20% 98%);
    --secondary-foreground: 25 95% 53%;
    --hsl-muted: hsl(215 27.9% 16.9%);
    --muted: 25 10% 90%;
    --hsl-muted-foreground: hsl(217.9 10.6% 64.9%);
    --muted-foreground: 25 10% 40%;
    --hsl-accent: hsl(215 27.9% 16.9%);
    --accent: 25 30% 95%;
    --hsl-accent-foreground: hsl(210 20% 98%);
    --accent-foreground: 25 95% 53%;
    --hsl-destructive: hsl(0 62.8% 30.6%);
    --destructive: 0 84% 60%;
    --hsl-destructive-foreground: hsl(210 20% 98%);
    --destructive-foreground: 0 0% 100%;
    --hsl-positive: hsl(120, 63%, 31%);
    --positive: 142 72% 29%;
    --hsl-positive-foreground: hsl(210 20% 98%);
    --positive-foreground: 0 0% 100%;
    --hsl-warning: hsl(30 80% 55%);
    --warning: 38 92% 50%;
    --hsl-warning-foreground: hsl(210 20% 98%);
    --warning-foreground: 0 0% 100%;
    --hsl-border: hsl(215 27.9% 16.9%);
    --border: 20 5% 90%;
    --hsl-input: hsl(215 27.9% 16.9%);
    --input: 20 5% 90%;
    --hsl-ring: hsl(263.4 70% 50.4%);
    --ring: 25 95% 53%;
    --hsl-chart-1: hsl(220 70% 50%);
    --chart-1: 220 70% 50%;
    --hsl-chart-2: hsl(160 60% 45%);
    --chart-2: 160 60% 45%;
    --hsl-chart-3: hsl(30 80% 55%);
    --chart-3: 30 80% 55%;
    --hsl-chart-4: hsl(280 65% 60%);
    --chart-4: 280 65% 60%;
    --hsl-chart-5: hsl(340 75% 55%);
    --chart-5: 340 75% 55%;
    --chart-energy-rgb: 52, 211, 153;
    /* #34D399 - Modern mint green */
    --chart-temperature-rgb: 251, 146, 60;
    /* #FB923C - Modern orange */
    --chart-cooling-rgb: 14, 165, 233;
    /* #0EA5E9 */
    --chart-heating-rgb: 239, 68, 68;
    /* #EF4444 */

    --font-sans: Roboto;
  }

  /* Add default text styling */
  body {
    @apply text-foreground;
  }

  /* Make all text elements inherit text-foreground by default */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div {
    @apply text-foreground;
  }

  /* Style specific heading variants */
  .heading-large {
    @apply text-2xl font-semibold;
  }

  .heading-medium {
    @apply text-xl font-medium;
  }

  .heading-small {
    @apply text-lg font-medium;
  }

  /* Style specific text variants */
  .text-muted {
    @apply text-muted-foreground;
  }
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
